import React, { useEffect, useMemo } from 'react';
// import DonutChart from '../../components/donutChart';
import { Box, Card, Grid, LinearProgress, Typography } from '@mui/material';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import chartIcon from '../../assets/images/chart.png';
import clipBoardIcon from '../../assets/images/clipboard.png';
import clockIcon from '../../assets/images/clock.png';
import clockHoursIcon from '../../assets/images/clock_hours.png';
import tagIcon from '../../assets/images/tag.png';
import DonutChart from '../../components/donutChart';
import UserStats from './UserStats';
import { getProjectOverviews } from './fieldUser/fieldUserSlice';

const OverViewPage = ({ projectID }) => {
  const { overviewData, isOverviewLoading } = useSelector((state) => state.projectUsers);
  const dispatch = useDispatch();
  useEffect(() => {
    if (projectID) {
      dispatch(getProjectOverviews({ projectID }));
    }
  }, [dispatch, projectID]);
  const allZeros = useMemo(() => {
    if (!overviewData) {
      return false;
    }
    return Object.values(overviewData).every((value) => value === 0);
  }, [overviewData]);

  return isOverviewLoading ? (
    <Card sx={{ mt: 1, height: 360 }}>
      <LinearProgress />
    </Card>
  ) : (
    <Card sx={{ mt: 1 }}>
      {allZeros ? (
        <Box height={360} display="flex" justifyContent="center" alignItems="center">
          <Typography>No Data Found</Typography>
        </Box>
      ) : (
        <Grid container>
          <Grid item xl={5} lg={5} sm={12} xs={12}>
            <Box sx={{ bgcolor: '#f9fafb', m: 2, p: 2, py: 6 }}>
              <Grid container rowSpacing={4} spacing={2}>
                <Grid item md={6} sm={6} lg={6}>
                  <UserStats
                    label="Average hours per day"
                    value={get(overviewData, 'averageHours', 0).toFixed(2)}
                    icon={clockIcon}
                  />
                </Grid>
                <Grid item md={6} sm={6} lg={6}>
                  <UserStats label="No. of Study Days" value={get(overviewData, 'studyDays', '')} icon={tagIcon} />
                </Grid>
                <Grid item md={6} sm={6} lg={6}>
                  <UserStats label="Total Hours" value={get(overviewData, 'totalHours', 0).toFixed(2)} icon={clockHoursIcon} />
                </Grid>
                <Grid item md={6} sm={6} lg={6}>
                  <UserStats label="Total Studies" value={get(overviewData, 'totalStudies', '')} icon={chartIcon} />
                </Grid>
                <Grid item md={6} sm={6} lg={6} sx={{ marginInline: 'auto' }}>
                  <UserStats main label="Total Observations" value={get(overviewData, 'observations', '')} icon={clipBoardIcon} />
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item lg={5} xl={7}>
            <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              {overviewData && <DonutChart overviewData={overviewData} />}
            </Box>
          </Grid>
        </Grid>
      )}
    </Card>
  );
};

OverViewPage.propTypes = {
  projectID: PropTypes.string,
};

export default OverViewPage;
