/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable eqeqeq */
import { closestCenter, DndContext, KeyboardSensor, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { restrictToFirstScrollableAncestor, restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { arrayMove, SortableContext, sortableKeyboardCoordinates, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { yupResolver } from '@hookform/resolvers/yup';
import CancelIcon from '@mui/icons-material/Cancel';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {
  alpha,
  Box,
  Card,
  Chip,
  Collapse,
  DialogActions,
  Grid,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material';
import dayjs from 'dayjs';
import { get, has } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import DownloadIcon from '@mui/icons-material/Download';
import EmptyIcon from '../../../assets/images/chart1.png';
import { ThemeButton } from '../../../components/button';
import CheckboxGroup from '../../../components/checkbox/CheckboxGroup';
import GroupCheckbox from '../../../components/formComponents/FormCheckbox';
import FormInput from '../../../components/formComponents/FormInput';
import FormRadioGroup from '../../../components/formComponents/formRadioGroup';
import ModalComponent from '../../../components/ModalComponent';
import { ReusableTabs } from '../../../components/reusableTabs';
import CustomSelect from '../../../components/select';
import { setSnackbar } from '../../../components/snackbar/snackbarSlice';
import { getStudyColor } from '../../../components/StudiesComponent';
import ThemeSwitch from '../../../components/switch';
import DataTable from '../../../components/table/VitrulisedTable';
import { Types } from '../../../types';
import { filterEmptyValues, isExistsInHub } from '../../../utils';
import axiosInstance from '../../../utils/axiosInstance';
import { addElementInProjectSchema, addToObservationSchema } from '../../../utils/schema';
import FilterModal from '../../admin/element/FilterModal';
import { addEc, addToObservation } from '../../studiesPage/studiesSlice';
import { getTasks, updateTask } from '../../tasksPage/taskSlice';
import { SortableElement } from '../../tasksPage/TasksNestedRow';
import { getAreas } from '../areas/areaSlice';
import { getCategories } from '../category/projectCategoriesSlice';
import { addElement, getElements } from '../elements/elementsSlice';
import { getRoles } from '../roles/rolesSlice';
import { getDataSummaries, getObservationCount, getStudiesStatus, removeSummary } from './dataSummarySlice';

const defaultDsPageColumnSettings = {
  rasStudy: {
    // 'Study Name': true,
    startTime: false,
    roleName: false,
    areaName: false,
    elementName: true,
    categoryName: true,
    rating: true,
    bms: true,
    frequency: true,
    bmsPerUom: true,
    notes: true,
    image: true,
  },
  dotsStudy: {
    observationStartTime: true,
    areaName: true,
    taskName: true,
    elementName: true,
    categoryName: true,
    rating: true,
    bms: true,
    frequency: true,
    bmsPerUom: true,
    notes: true,
    image: true,
  },
  diloStudy: {
    observationStartTime: true,
    areaName: true,
    taskName: true,
    roleName: true,
    elementName: true,
    categoryName: true,
    rating: true,
    bms: true,
    frequency: true,
    bmsPerUom: true,
    notes: true,
    image: true,
  },
};
const DataSummaryPage = ({ projectID, singleProjectData, customerID }) => {
  const [currentStudyType, setCurrentStudyType] = useState(null);
  const [validStudies, setValidStudies] = useState([]);
  const dispatch = useDispatch();
  useEffect(() => {
    if (projectID && currentStudyType) {
      dispatch(getDataSummaries({ projectID, studyType: currentStudyType }));
      dispatch(getObservationCount({ projectID, studyType: currentStudyType }));
    }
  }, [currentStudyType, dispatch, projectID]);
  useEffect(() => {
    if (projectID) {
      dispatch(getStudiesStatus({ id: projectID })).then((response) => {
        if (get(response, 'payload.success')) {
          setValidStudies(get(response, 'payload.data'));
        }
      });
    }
  }, [currentStudyType, dispatch, projectID]);
  const [rolesOptions, setRolesOptions] = useState([]);

  const fetchDataSummaries = () => {
    if (projectID && currentStudyType) {
      dispatch(getDataSummaries({ projectID, studyType: currentStudyType }));
      dispatch(getObservationCount({ projectID, studyType: currentStudyType }));
    }
  };
  const { dataSummaries, isLoading, isStudyStatusLoading, obsLoading, obsCount } = useSelector((state) => state.dataSummaries);
  const { singleStudyData, addObservationLoading } = useSelector((state) => state.studies);
  const { tasks } = useSelector((state) => state.tasks);
  const [isModalopen, setisModalopen] = useState(false);
  const { areas } = useSelector((state) => state.areas);
  const { elements, addElementLoading } = useSelector((state) => state.projectElements);
  const { roles } = useSelector((state) => state?.roles);
  const [isColumnsModalOpened, setColumnsModal] = useState(false);
  const [filteredColSettings, setFilteredColSettings] = useState(defaultDsPageColumnSettings);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [sortBy, setSortBy] = useState('');
  const [filterBy, setFilterBy] = useState('');
  const [isObsModalOpen, setIsObsModalOpen] = useState(false);
  const [filterOptions, setFilterOptions] = useState([]);
  const [elementRating, setElementRating] = useState(false);
  const [selectedElementTab, setSelectedElementTab] = useState('createElement');
  const [elementCount, setElementCount] = useState(false);
  const [isPhotoDownloading, setPhotosDownloading] = useState([]);
  const [photoDownloadingId, setPhotosDownloadingId] = useState([]);
  const [currentTask, setCurrentTask] = useState('');
  const [collapsedRow, setCollapsedRow] = useState('');
  const [filterConfig, setFilterConfig] = useState({
    filters: {}, // Stores filters for each column
    sortOrder: '',
    sortBy: '',
  });
  const [delayedLoading, setDelayedLoading] = useState(true);

  useEffect(() => {
    if (!isLoading) {
      const timer = setTimeout(() => {
        setDelayedLoading(false);
      }, 160);

      return () => clearTimeout(timer); // ✅ Return cleanup function
    }
    setDelayedLoading(true);
    return undefined;
  }, [isLoading]);
  const defaultRolesOptions = useMemo(() => {
    if (roles && !!roles?.length) {
      return roles.map((role) => ({ label: role?.name, value: role?._id }));
    }
    return [];
  }, [roles]);
  useEffect(() => {
    if (roles && !!roles?.length) {
      const optionsRoles = roles.map((role) => ({ label: role?.name, value: role?._id }));
      setRolesOptions(() => optionsRoles);
    } else {
      setRolesOptions(() => []);
    }
  }, [roles]);
  const onElementTabChange = (event, newValue) => setSelectedElementTab(newValue);
  useEffect(() => {
    const dsPageColumnSettings = JSON.parse(localStorage.getItem('dsPageColumnSettings'));
    if (
      !dsPageColumnSettings ||
      !dsPageColumnSettings?.rasStudy ||
      !dsPageColumnSettings?.dotsStudy ||
      !dsPageColumnSettings?.diloStudy
    ) {
      localStorage.setItem('dsPageColumnSettings', JSON.stringify(defaultDsPageColumnSettings));
    } else {
      setFilteredColSettings(dsPageColumnSettings);
    }
  }, []);
  const { projectCategories: categories } = useSelector((state) => state.projectCategories);

  useEffect(() => {
    if (projectID) {
      dispatch(getRoles({ projectID }));
      dispatch(getAreas({ projectID }));
      dispatch(getElements({ projectID, defaultEntry: false }));
      dispatch(getTasks({ projectID }));
      dispatch(getCategories({ projectID, defaultEntry: false }));
    }
  }, [dispatch, projectID]);
  const form = useForm({
    defaultValues: {
      studyID: '',
      roundID: '',
      addAtObservationID: '',
      projectID,
      areaID: '',
      elementID: '',
      roleID: '',
      rating: '',
      frequency: null,
      notes: '',
    },
    resolver: yupResolver(addToObservationSchema),
  });
  const { register, watch, handleSubmit, formState, reset, getValues, setValue, control } = form;
  const { errors } = formState;
  const elementID = watch('elementID');
  const form2 = useForm({
    defaultValues: {
      // defaultEntry: true,
      name: '',
      unitOfMeasure: '',
      studyTypes: [],
      tasks: [],
      rating: 3,
      type: 2,
      count: false,
      contingencyAllowance: null,
      relaxationAllowance: null,
      categoryID: '',
      currentElement: '',
    },
    resolver: selectedElementTab === 'createElement' && yupResolver(addElementInProjectSchema),
  });
  // const { register, watch, handleSubmit, formState, reset, getValues, setValue, control } = form2x
  // const { fields, append, prepend, remove, swap, move, insert } = useFieldArray({
  const {
    append,
    fields: fields2,
    remove,
  } = useFieldArray({
    control: form2?.control, // control props comes from useForm (optional: if you are using FormProvider)
    name: 'tasks', // unique name for your Field Array
  });
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentElementname, setCurrentElementName] = useState('');
  const open = Boolean(anchorEl);
  const addTaskClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const ratingsOptions = useMemo(
    () => [
      {
        label: 'Not Rated',
        description: 'No rating screen in app, SMV not shown in CMS or in any values. Only in data summary export',
        value: 1,
      },
      { label: '100', description: 'No rating screen in app, element defaults to 100 rating', value: 2 },
      { label: 'Field User Input', description: 'Show rating screen in the app for the element', value: 3 },
    ],
    []
  );
  const typeOptions = useMemo(
    () => [
      { label: 'Fixed', value: 1 },
      { label: 'Variable', value: 2 },
    ],
    []
  );
  const handleClose = () => {
    setAnchorEl(null);
  };
  const tempID = useMemo(() => 'tempID', []);
  const categoryFieldOptions = useMemo(
    () =>
      categories && categories?.length > 0
        ? categories
            .filter((category) => has(category, 'projectSetting.status') && category.projectSetting.status === 'active')
            .map((category) => ({ label: category.name, value: category._id }))
        : [],
    [categories]
  );
  const handleSelectTask = (event, value) => {
    const selectedTask = tasks.find((task) => task?._id === value);
    if (selectedTask) {
      const newTask = {
        taskID: selectedTask?._id,
        taskName: selectedTask?.name,
        elements: [tempID, ...get(selectedTask, 'projectSetting.elements', [])],
      };
      append(newTask);
    }
    handleClose();
  };
  const studyTypesValues = form2?.watch('studyTypes');
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );
  const openModal = () => {
    setisModalopen(true);
    if (currentStudyType === 1) {
      form2.setValue('studyTypes', [1]);
    } else {
      form2.setValue('studyTypes', [2, 3]);
    }
    if (currentTask) {
      handleSelectTask('', currentTask);
    }
  };
  const closeModal = () => {
    setisModalopen((prevState) => !prevState);
    form2?.reset();
    form2?.setValue('tasks', []);
    setSelectedElementTab('createElement');
  };
  useEffect(() => {
    if (elementID) {
      const element = elements.find((curElement) => curElement?._id === elementID);
      if (element && element?.projectSetting?.rating) {
        setElementRating(element?.projectSetting?.rating);
      } else {
        setElementRating(null);
      }
      if (get(element, 'projectSetting.count') !== undefined && get(element, 'projectSetting.count') === false) {
        setValue('frequency', 1);
      }
      setElementCount(get(element, 'projectSetting.count', true));
    } else {
      setElementRating(null);
      setElementCount(true);
    }
  }, [elementID, elements, setValue]);
  const tasksOptions = useMemo(
    () => (tasks?.length > 0 ? tasks?.map((taskElement) => ({ label: taskElement?.name, value: taskElement?._id })) : []),
    [tasks]
  );
  const summaryRows = useMemo(() => {
    if (currentStudyType && dataSummaries && dataSummaries?.length > 0) {
      return dataSummaries?.map((summary) => {
        let formattedTime = '';
        let observationStartTime = '';
        if (summary?.roundStartTime) {
          const dateTime = dayjs(summary?.roundStartTime); // Ensure it's interpreted as UTC
          formattedTime = dateTime.format('HH:mm');
          const tz = summary?.studyTimezone || summary?.studyTimeZone;
          if (tz) {
            formattedTime = dateTime.tz(tz)?.format('HH:mm');
          } else if (singleProjectData?.timezone) {
            formattedTime = dateTime.tz(singleProjectData?.timezone)?.format('HH:mm');
          } else {
            formattedTime = dateTime.tz('Europe/London')?.format('HH:mm');
          }
        }
        if (summary?.startTime) {
          const dateTime = dayjs(summary?.startTime).utc(); // Ensure it's interpreted as UTC
          // Format the time to hours and minutes
          let formattedStartTime = dateTime.format('HH:mm');
          const tz = summary?.studyTimezone || summary?.studyTimeZone;
          if (tz) {
            formattedStartTime = dateTime.tz(tz)?.format('HH:mm');
          } else if (singleProjectData?.timezone) {
            formattedStartTime = dateTime.tz(singleProjectData?.timezone)?.format('HH:mm');
          } else {
            formattedStartTime = dateTime.tz('Europe/London')?.format('HH:mm');
          }
          // observationStartTime = formattedTime;
          observationStartTime = formattedStartTime;
        }
        return {
          ...summary,
          startTime: formattedTime,
          // bms: get(summary, 'bms')?.toString(),
          // bmsPerUom: get(summary, 'bmsPerUom')?.toString(),
          bms: get(summary, 'bms') && parseFloat(get(summary, 'bms').toFixed(2)),
          bmsPerUom: get(summary, 'bmsPerUom') && parseFloat(get(summary, 'bmsPerUom').toFixed(2)),
          frequency: get(summary, 'frequency')?.toString(),
          roleName: get(summary, 'roleName') || '',
          _id: summary?.observationID,
          studyType: currentStudyType,
          roundID: summary?.roundID,
          isEc: get(summary, 'originalValues') && Object.keys(get(summary, 'originalValues')).length > 0,
          observationStartTime,
          isDuplicateStudy: get(summary, 'isResync'),
          categoryName: summary?.categoryName || '',
          elementName: summary?.elementName || '',
          taskName: summary?.taskName || '',
          areaName: summary?.areaName || '',
          locationName: summary?.locationName || '',
          notes: summary?.notes || '',
          photo: summary?.photo || '',
        };
      });
    }
    return [];
  }, [currentStudyType, dataSummaries, singleProjectData?.timezone]);
  const taskSelectOptions = useMemo(() => {
    if (tasks && !!tasks?.length) {
      return tasks
        .filter((task) => task?.projectSetting?.status === 'active')
        .map((task) => ({ label: task?.name, value: task?._id }));
    }
    return [];
  }, [tasks]);
  const obsRatingOptions = useMemo(() => {
    const array = [];
    for (let i = 60; i <= 120; i += 5) {
      array.push({ label: i.toString(), value: i.toString() });
    }
    array.push({ label: 'Not Rated', value: 'Not Rated' });
    return array;
  }, []);
  const obsFilterRatingOptions = useMemo(() => {
    if (summaryRows && summaryRows?.length) {
      const filterObsRatingOptions = [...new Set(summaryRows.map((studyRow) => studyRow?.rating))];
      return filterObsRatingOptions
        .sort((a, b) => {
          const isANumeric = !Number.isNaN(Number(a));
          const isBNumeric = !Number.isNaN(Number(b));

          if (isANumeric && isBNumeric) {
            // Both are numeric, sort numerically
            return Number(a) - Number(b);
          }
          if (!isANumeric) {
            // `a` is not numeric, place it after `b`
            return 1;
          }
          if (!isBNumeric) {
            // `b` is not numeric, place it after `a`
            return -1;
          }
          // In case both are non-numeric, maintain original order
          return 0;
        })
        .map((obsRating) => ({ label: obsRating, value: obsRating }));
    }
    return [];
  }, [summaryRows]);
  const rolesSelectOptions = useMemo(() => {
    if (roles && roles.length > 0) {
      return roles
        .filter(
          (role) => role?.projectSetting?.studyTypes?.includes(currentStudyType) && role?.projectSetting?.status === 'active'
        )
        .map((role) => ({ label: role?.name, value: role?._id }));
    }
    return [];
  }, [roles, currentStudyType]);
  const areasOptions = useMemo(() => {
    if (areas && areas.length > 0) {
      return areas
        .filter(
          (area) => area?.projectSetting?.studyTypes?.includes(currentStudyType) && area?.projectSetting?.status === 'active'
        )
        .map((area) => ({ label: area?.name, value: area?._id }));
    }
    return [];
  }, [areas, currentStudyType]);
  const elementSelectOptions = useMemo(() => {
    if (elements && elements.length > 0) {
      return elements
        .filter(
          (element) =>
            element?.projectSetting?.studyTypes?.includes(currentStudyType) && element?.projectSetting?.status === 'active'
        )
        .map((element) => ({ label: element?.name, value: element?._id }));
    }
    return [];
  }, [elements, currentStudyType]);
  const startTimeOptions = useMemo(() => {
    if (summaryRows && summaryRows?.length) {
      const uniqueStartTime = [...new Set(summaryRows.map((studyRow) => studyRow.startTime))];
      return uniqueStartTime
        .sort((a, b) => dayjs(a, 'HH:mm') - dayjs(b, 'HH:mm'))
        ?.map((startTime) => ({ label: startTime, value: startTime }));
    }
    return [];
  }, [summaryRows]);
  const roleOptions = useMemo(() => {
    if (summaryRows && summaryRows?.length) {
      const uniqueRoles = [...new Set(summaryRows.map((studyRow) => studyRow.roleName || ''))];
      return uniqueRoles
        .sort((a, b) => a.localeCompare(b))
        .map((roleName) => (isExistsInHub(roleName) ? { label: roleName, value: roleName } : { label: 'No Role', value: '' }));
    }
    return [];
  }, [summaryRows]);
  const areaOptions = useMemo(() => {
    if (summaryRows && summaryRows?.length) {
      const uniqueAreaNames = [...new Set(summaryRows.map((studyRow) => (studyRow.areaName ? studyRow.areaName : '')))];
      return uniqueAreaNames
        .sort((a, b) => a.localeCompare(b))
        .map((areaName) => (isExistsInHub(areaName) ? { label: areaName, value: areaName } : { label: 'No Area', value: '' }));
    }
    return [];
  }, [summaryRows]);
  const timeOptions = useMemo(() => {
    if (summaryRows && summaryRows?.length) {
      const uniqueObservation = [
        ...new Set(summaryRows.map((studyRow) => (studyRow.observationStartTime ? studyRow.observationStartTime : ''))),
      ];
      return uniqueObservation
        .sort((a, b) => dayjs(a, 'HH:mm') - dayjs(b, 'HH:mm'))
        .map((areaName) => (isExistsInHub(areaName) ? { label: areaName, value: areaName } : { label: 'No Time', value: '' }));
    }
    return [];
  }, [summaryRows]);
  const notesOptions = useMemo(() => {
    if (summaryRows && summaryRows?.length) {
      const uniqueAreaNames = [...new Set(summaryRows.map((studyRow) => studyRow.notes || ''))];
      return uniqueAreaNames
        .sort((a, b) => a.localeCompare(b))
        .map((note) => (isExistsInHub(note) ? { label: note, value: note } : { label: 'No Note', value: '' }));
    }
    return [];
  }, [summaryRows]);
  const elementOptions = useMemo(() => {
    if (!summaryRows?.length) return [];

    // Ensure unique elements using reduce()
    const uniqueElements = Object.values(
      summaryRows.reduce((acc, studyRow, index) => {
        if (studyRow?.elementID) {
          if (filterConfig?.filters?.taskName?.length > 0) {
            if (filterConfig?.filters?.taskName.some((filterTask) => filterTask === studyRow?.taskName)) {
              acc[studyRow.elementID] = { elementName: studyRow.elementName, elementID: studyRow.elementID };
            }
          } else {
            acc[studyRow.elementID] = { elementName: studyRow.elementName, elementID: studyRow.elementID };
          }
        } else if (!acc.hasEmpty) {
          if (!Object.values(acc).some((val) => val?.elementName === '')) {
            acc[index] = { elementName: '', elementID: '' };
          }
        }
        return acc;
      }, {})
    );

    if (filterConfig?.filters?.taskName?.length > 0) {
      const taskNames = new Set(filterConfig?.filters.taskName);

      // Convert task list into a lookup object instead of using a loop
      const taskElementsMap = tasks?.reduce((acc, task) => {
        if (taskNames.has(task.name)) {
          acc[task.name] = new Set(task.projectSetting?.elements || []);
        }
        return acc;
      }, {});

      const filteredUniqElements = uniqueElements
        .filter(
          (element) =>
            element?.elementName &&
            Object.values(taskElementsMap).some((currentElements) => currentElements.has(element?.elementID))
        )
        .sort((a, b) => (a?.elementName || '').localeCompare(b?.elementName || ''));
      const noElementOption = { label: 'No Element', value: '' };
      let hasAddedNoElement = false;
      const result = filteredUniqElements.reduce((acc, element) => {
        const name = element?.elementName || '';

        if (isExistsInHub(name)) {
          acc.push({ label: name, value: name });
        } else if (!hasAddedNoElement) {
          acc.push(noElementOption);
          hasAddedNoElement = true;
        }

        return acc;
      }, []);
      return result;
      // .map((element) =>
      //   element?.elementName
      //     ? { label: element?.elementName || '', value: element?.elementName || '' }
      //     : { label: 'No Element', value: '' }
      // );
    }
    const filteredUniqElement = uniqueElements.sort((a, b) => (a?.elementName || '').localeCompare(b?.elementName || ''));
    const noElementOption = { label: 'No Element', value: '' };
    let hasAddedNoElement = false;
    const result = filteredUniqElement.reduce((acc, element) => {
      const name = element?.elementName || '';
      if (isExistsInHub(name)) {
        acc.push({ label: name, value: name });
      } else if (!hasAddedNoElement) {
        acc.push(noElementOption);
        hasAddedNoElement = true;
      }

      return acc;
    }, []);
    return result;

    // return uniqueElements
    //   .sort((a, b) => (a?.elementName || '').localeCompare(b?.elementName || ''))
    //   .map((element) =>
    //     element?.elementName
    //       ? { label: element?.elementName || '', value: element?.elementName || '' }
    //       : { label: 'No Element', value: '' }
    //   );
  }, [filterConfig, summaryRows, tasks]);
  const categoryOptions = useMemo(() => {
    if (summaryRows && summaryRows?.length) {
      const ctegoryElements = [...new Set(summaryRows.map((studyRow) => (studyRow.categoryName ? studyRow.categoryName : '')))];
      return ctegoryElements
        .sort((a, b) => a.localeCompare(b))
        ?.filter((catName) => catName !== '-' && catName !== '')
        .map((categoryName) =>
          isExistsInHub(categoryName) ? { label: categoryName, value: categoryName } : { label: 'No Category', value: '' }
        );
    }
    return [];
  }, [summaryRows]);
  const ratingOptions = useMemo(() => {
    if (summaryRows && summaryRows?.length) {
      const ratingElements = [...new Set(summaryRows.map((studyRow) => studyRow.rating))];
      return ratingElements.sort((a, b) => a - b).map((rating) => ({ label: rating, value: rating }));
    }
    return [];
  }, [summaryRows]);
  const bmsOptions = useMemo(() => {
    if (summaryRows && summaryRows?.length) {
      const bmsElements = [...new Set(summaryRows.map((studyRow) => studyRow.bms))];
      return bmsElements.sort((a, b) => a - b)?.map((bms) => ({ label: bms, value: bms }));
    }
    return [];
  }, [summaryRows]);
  const bmsPerUomOptions = useMemo(() => {
    if (summaryRows && summaryRows?.length) {
      const bmsElements = [...new Set(summaryRows.map((studyRow) => studyRow.bmsPerUom))];
      return bmsElements.sort((a, b) => a - b).map((bmsPerUom) => ({ label: bmsPerUom, value: bmsPerUom }));
    }
    return [];
  }, [summaryRows]);
  const frequencyOptions = useMemo(() => {
    if (summaryRows && summaryRows?.length) {
      const frequencyElements = [...new Set(summaryRows.map((studyRow) => studyRow.frequency))];
      return frequencyElements.sort((a, b) => a - b).map((frequency) => ({ label: frequency, value: frequency }));
    }
    return [];
  }, [summaryRows]);
  const studynameOptions = useMemo(() => {
    if (summaryRows && summaryRows?.length) {
      const studyElements = [...new Set(summaryRows.map((studyRow) => studyRow.studyName))];
      return studyElements.sort((a, b) => a.localeCompare(b)).map((studyName) => ({ label: studyName, value: studyName }));
    }
    return [];
  }, [summaryRows]);
  const taskOptions = useMemo(() => {
    if (summaryRows && summaryRows?.length) {
      const uniqueTaskNames = [...new Set(summaryRows.map((studyRow) => (studyRow.taskName ? studyRow.taskName : '')))];
      return uniqueTaskNames
        .sort((a, b) => a.localeCompare(b))
        .map((taskName) => (isExistsInHub(taskName) ? { label: taskName, value: taskName } : { label: 'No Task', value: '' }));
    }
    return [];
  }, [summaryRows]);
  const openFilter = (key, orderBy, isLabelNumeric, sortType) => {
    setIsFilterOpen(true);
    setFilterBy(key);
    if (orderBy) {
      setSortBy(orderBy);
    }
    if (isLabelNumeric) {
      setFilterConfig((prevConfig) => ({ ...prevConfig, isLabelNumeric: true }));
    } else {
      setFilterConfig((prevConfig) => ({ ...prevConfig, isLabelNumeric: false }));
    }
    if (sortType) {
      setFilterConfig((prevConfig) => ({ ...prevConfig, sortType }));
    } else {
      setFilterConfig((prevConfig) => ({ ...prevConfig, sortType: 'string' }));
    }
  };
  const onEditClick = (event, row, nestedCollapsedRows, setNestedCollapsedRows) => {
    if (nestedCollapsedRows.indexOf(row?._id) !== -1) {
      setNestedCollapsedRows(nestedCollapsedRows.filter((currCollapsedRow) => currCollapsedRow !== row?._id));
    } else {
      setNestedCollapsedRows((prevRows) => [row?._id]);
    }
  };
  const tasksValues = form2?.watch('tasks');
  const handleDragEnd = (event, field, index) => {
    const { active, over } = event;
    const currentElements = field?.elements;
    const activeIndex = currentElements?.findIndex((element) => element === active.id);
    const overIndex = currentElements?.findIndex((element) => element === over.id);
    const sortedTempArray = arrayMove(currentElements, activeIndex, overIndex);
    const taskValToUpdate = [...tasksValues];
    taskValToUpdate[index].elements = sortedTempArray;
    form2?.setValue('tasks', taskValToUpdate);
  };
  const closeFilter = () => setIsFilterOpen(false);
  const downloadPhoto = async (cell) => {
    // Check if cell has a photo value
    if (cell?.photo) {
      try {
        // Split the photo URLs by comma, if there are multiple
        const photoUrls = cell.photo.split(',').map((url) => url.trim()); // Trim each URL
        setPhotosDownloading(true);
        setPhotosDownloadingId(() => cell?._id);
        // Use forEach to iterate through the URLs
        await Promise.all(
          photoUrls.map(async (url) => {
            try {
              // Make the API call for each photo URL
              const response = await axiosInstance({
                url: 'downloads/file',
                method: 'POST',
                data: {
                  filekey: url, // Use the URL in the API call
                  type: 'photo',
                },
                responseType: 'blob',
              });

              if (response.status !== 200) {
                console.log('Failed to fetch file for URL:', url);
                return; // Skip this photo and continue with the next one
              }

              // Create a temporary URL for the file
              const fileUrl = window.URL.createObjectURL(response.data);

              // Create an invisible <a> tag to trigger the download
              const link = document.createElement('a');
              link.href = fileUrl;
              link.download = url.split('/').pop(); // Use the filename from the URL as the download name
              document.body.appendChild(link);
              link.click();

              // Clean up: remove the <a> tag after the download
              document.body.removeChild(link);
              window.URL.revokeObjectURL(fileUrl); // Free up memory by revoking the object URL
            } catch (error) {
              console.log('Error downloading photo:', error);
            }
          })
        );
      } catch (error) {
        console.log('Error in processing photos:', error);
      } finally {
        setPhotosDownloading(false);
        setPhotosDownloadingId('');
      }
    }
  };
  const getStudyName = (studyType) => {
    if (!studyType) {
      return '';
    }
    if (studyType == 1) {
      return 'Efficiency';
    }
    if (studyType == 2) {
      return 'Role';
    }
    if (studyType == 3) {
      return 'Activity';
    }
    return '';
  };
  const getPhotoCount = (cell) => {
    if (cell?.photo) {
      return cell?.photo?.split(',')?.length;
    }
    return '';
  };
  const headCells = useMemo(
    () => [
      {
        id: 'studyName',
        numeric: false,
        label: 'Study Name',
        renderElement: (cell) =>
          cell?.studyName ? (
            <Typography
              sx={{
                fontSize: '13px',
                fontWeight: 400,
                overflowWrap: 'break-word',
                display: 'inline-block',
                maxWidth: '150px',
                wordBreak: 'break-word',
              }}
            >
              {cell?.studyName}
            </Typography>
          ) : null,
        onClick: () => openFilter('studyName', 'studyName'),
        width: 140,
      },
      {
        id: 'isEc',
        // maxWidth: 36,
        align: 'left',
        renderElement: (cell) =>
          cell?.isEc ? (
            <Typography
              sx={{
                backgroundColor: 'primary.main',
                color: '#fff',
                ml: '15px',
                padding: '3px 6px',
                borderRadius: '21px',
                width: '27px',
              }}
              fontSize="small"
            >
              EC
            </Typography>
          ) : null,
      },
      {
        id: 'observationStartTime',
        numeric: false,
        label: 'Time',
        onClick: () => openFilter('observationStartTime', 'observationStartTime', false, 'observationTime'),
      },
      {
        id: 'roleName',
        numeric: false,
        label: 'Role',
        renderElement: (cell) => (
          <Typography
            sx={{
              fontSize: '14px',
              fontWeight: 400,
              color: cell?.roleStatus === 'archive' ? '#FF4842' : '#212B36',
            }}
          >
            {cell?.roleName}
          </Typography>
        ),
        // minWidth: 170,
        onClick: () => openFilter('roleName', 'roleName'),
      },
      {
        id: 'areaName',
        numeric: false,
        label: 'Area',
        renderElement: (cell) => (
          <Typography
            sx={{
              fontSize: '14px',
              fontWeight: 400,
              color: cell?.areaStatus === 'archive' ? '#FF4842' : '#212B36',
            }}
          >
            {cell?.areaName}
          </Typography>
        ),
        // minWidth: 170,
        onClick: () => openFilter('areaName', 'areaName'),
      },
      {
        id: 'taskName',
        disablePadding: false,
        label: 'Task',
        onClick: () => openFilter('taskName', 'taskName'),
        renderElement: (cell) => (
          <Typography
            sx={{
              fontSize: '14px',
              fontWeight: 400,
              color: cell?.taskStatus === 'archive' ? '#FF4842' : '#212B36',
            }}
          >
            {cell?.taskName}
          </Typography>
        ),
        // renderElement: (cell) => <StudiesComponent studyTypes={cell?.studyTypes} />,
      },
      {
        id: 'elementName',
        numeric: false,
        label: 'Element Name',
        renderElement: (cell) => (
          <Typography
            sx={{
              fontSize: '14px',
              fontWeight: 400,
              color: cell?.elementStatus === 'archive' ? '#FF4842' : '#212B36',
            }}
          >
            {cell?.elementName}
          </Typography>
        ),
        // minWidth: 190,
        onClick: () => openFilter('elementName', 'elementName'),
      },
      {
        id: 'categoryName',
        numeric: false,
        label: 'Category',
        renderElement: (cell) => (
          <Typography
            sx={{
              fontSize: '14px',
              fontWeight: 400,
              color: cell?.categoryStatus === 'archive' ? '#FF4842' : '#212B36',
            }}
          >
            {cell?.categoryName}
          </Typography>
        ),
        // minWidth: 180,
        onClick: () => openFilter('categoryName', 'categoryName'),
      },
      {
        id: 'rating',
        numeric: false,
        label: 'Rating',
        onClick: () => openFilter('rating', 'rating', true),
      },
      {
        id: 'bms',
        numeric: false,
        label: 'BMS',
        onClick: () => openFilter('bms', 'bms', true),
        renderElement: (cell) =>
          cell?.bms && (
            <Stack alignItems="center" justifyContent="center" sx={{ pr: 2 }}>
              {/* <Chip size="small" sx={{ minWidth: 70, bgcolor: cell?.bmsFormatting || '' }} label={cell?.bms} /> */}
              <Chip size="small" sx={{ minWidth: 70 }} label={cell?.bms} />
            </Stack>
          ),
        align: 'center',
        // minWidth: 150,
      },
      {
        id: 'frequency',
        numeric: false,
        label: 'Freq',
        onClick: () => openFilter('frequency', 'frequency', true),
      },
      {
        id: 'bmsPerUom',
        numeric: false,
        label: 'BMS/UOM',
        onClick: () => openFilter('bmsPerUom', 'bmsPerUom', true),
      },
      {
        id: 'notes',
        numeric: false,
        label: 'Notes',
        onClick: () => openFilter('notes', 'notes'),
        // minWidth: 230,
      },
      {
        id: 'photo',
        numeric: false,
        label: 'Images',
        renderElement: (cell) =>
          cell?.photo ? (
            <ThemeButton
              loading={isPhotoDownloading && photoDownloadingId === cell?._id}
              onClick={() => downloadPhoto(cell)}
              variant="outlined"
              startIcon={<DownloadIcon />}
            >
              {getPhotoCount(cell)}
            </ThemeButton>
          ) : null,
      },
    ],
    [isPhotoDownloading, photoDownloadingId]
  );
  const rasHeadCells = useMemo(
    () => [
      {
        id: 'studyName',
        numeric: false,
        label: 'Study Name',
        renderElement: (cell) =>
          cell?.studyName ? (
            <Typography
              sx={{
                fontSize: '13px',
                fontWeight: 400,
                overflowWrap: 'break-word',
                display: 'inline-block',
                maxWidth: '150px',
                wordBreak: 'break-word',
              }}
            >
              {cell?.studyName}
            </Typography>
          ) : null,
        onClick: () => openFilter('studyName', 'studyName'),
        width: 140,
      },
      {
        id: 'startTime',
        numeric: false,
        label: 'Rnd',
        onClick: () => openFilter('startTime', 'startTime', false, 'observationTime'),
      },
      {
        id: 'roleName',
        numeric: false,
        label: 'Role',
        renderElement: (cell) => (
          <Typography
            sx={{
              fontSize: '14px',
              fontWeight: 400,
              color: cell?.roleStatus === 'archive' ? '#FF4842' : '#212B36',
            }}
          >
            {cell?.roleName}
          </Typography>
        ),
        onClick: () => openFilter('roleName', 'roleName'),
      },
      {
        id: 'areaName',
        numeric: false,
        label: 'Area',
        renderElement: (cell) => (
          <Typography
            sx={{
              fontSize: '14px',
              fontWeight: 400,
              color: cell?.areaStatus === 'archive' ? '#FF4842' : '#212B36',
            }}
          >
            {cell?.areaName}
          </Typography>
        ),
        // minWidth: 170,
        onClick: () => openFilter('areaName', 'areaName'),
      },
      {
        id: 'elementName',
        numeric: false,
        label: 'Element Name',
        renderElement: (cell) => (
          <Typography
            sx={{
              fontSize: '14px',
              fontWeight: 400,
              color: cell?.elementStatus === 'archive' ? '#FF4842' : '#212B36',
            }}
          >
            {cell?.elementName}
          </Typography>
        ),
        // minWidth: 190,
        onClick: () => openFilter('elementName', 'elementName'),
      },
      {
        id: 'categoryName',
        numeric: false,
        label: 'Category',
        renderElement: (cell) => (
          <Typography
            sx={{
              fontSize: '14px',
              fontWeight: 400,
              color: cell?.categoryStatus === 'archive' ? '#FF4842' : '#212B36',
            }}
          >
            {cell?.categoryName}
          </Typography>
        ),
        // minWidth: 180,
        onClick: () => openFilter('categoryName', 'categoryName'),
      },
      {
        id: 'rating',
        numeric: false,
        label: 'Rating',
        onClick: () => openFilter('rating', 'rating', true),
      },
      {
        id: 'bms',
        numeric: false,
        label: 'BMS',
        onClick: () => openFilter('bms', 'bms', true),
        renderElement: (cell) =>
          cell?.bms && (
            <Stack alignItems="center" justifyContent="center" sx={{ pr: 2 }}>
              {/* <Chip size="small" sx={{ minWidth: 70, bgcolor: cell?.bmsFormatting || '' }} label={cell?.bms} /> */}
              <Chip size="small" sx={{ minWidth: 70 }} label={cell?.bms} />
            </Stack>
          ),
        align: 'center',
        minWidth: 150,
      },
      {
        id: 'frequency',
        numeric: false,
        label: 'Freq',
        onClick: () => openFilter('frequency', 'frequency', true),
      },
      {
        id: 'bmsPerUom',
        numeric: false,
        label: 'BMS/UOM',
        onClick: () => openFilter('bmsPerUom', 'bmsPerUom', true),
      },
      {
        id: 'notes',
        numeric: false,
        label: 'Notes',
        onClick: () => openFilter('notes', 'notes'),
        // minWidth: 250,
      },
      {
        id: 'photo',
        numeric: false,
        label: 'Images',
        renderElement: (cell) =>
          cell?.photo ? (
            <ThemeButton
              loading={isPhotoDownloading && photoDownloadingId === cell?._id}
              onClick={() => downloadPhoto(cell)}
              variant="outlined"
              startIcon={<DownloadIcon />}
            >
              {getPhotoCount(cell)}
            </ThemeButton>
          ) : null,
      },
    ],
    [isPhotoDownloading, photoDownloadingId]
  );

  useEffect(() => {
    switch (filterBy) {
      case 'startTime':
        setFilterOptions(startTimeOptions);
        break;
      case 'roleName':
        setFilterOptions(roleOptions);
        break;
      case 'areaName':
        setFilterOptions(areaOptions);
        break;
      case 'elementName':
        setFilterOptions(elementOptions);
        break;
      case 'categoryName':
        setFilterOptions(categoryOptions);
        break;
      case 'rating':
        setFilterOptions(obsFilterRatingOptions);
        break;
      case 'bms':
        setFilterOptions(bmsOptions);
        break;
      case 'bmsPerUom':
        setFilterOptions(bmsPerUomOptions);
        break;
      case 'frequency':
        setFilterOptions(frequencyOptions);
        break;
      case 'studyName':
        setFilterOptions(studynameOptions);
        break;
      case 'taskName':
        setFilterOptions(taskOptions);
        break;
      case 'observationStartTime':
        setFilterOptions(timeOptions);
        break;
      case 'notes':
        setFilterOptions(notesOptions);
        break;
      default:
        setFilterOptions([]);
        break;
    }
  }, [
    areaOptions,
    bmsOptions,
    bmsPerUomOptions,
    categoryOptions,
    elementOptions,
    filterBy,
    frequencyOptions,
    notesOptions,
    obsFilterRatingOptions,
    obsRatingOptions,
    ratingOptions,
    roleOptions,
    startTimeOptions,
    studynameOptions,
    taskOptions,
    taskSelectOptions,
    timeOptions,
  ]);
  const studyTypeConfig = { label: 'label', value: 'value' };
  const studyTypeOptions = useMemo(() => {
    if (currentStudyType === 1) {
      return [{ label: 'Efficiency Study', value: 1, color: '#f59d0f' }];
    }
    return [
      { label: 'Role Study', value: 2, color: '#784fc2' },
      { label: 'Activity Study', value: 3, color: '#0ba0ef' },
    ];
  }, [currentStudyType]);
  const rowClicked = (element) => {
    if (collapsedRow === element) {
      setCollapsedRow('');
    } else {
      setCollapsedRow(element);
    }
  };
  const onSubmit = (values) => {
    const filteredPayload = filterEmptyValues(values);
    dispatch(addToObservation(filteredPayload))
      .then((response) => {
        if (response.payload?.success) {
          dispatch(
            setSnackbar({
              snackbarOpen: true,
              snackbarType: 'success',
              snackbarMessage: get(response, 'payload.message', 'Observation Added Successfully'),
            })
          );
          fetchDataSummaries();
          closeObsModal();
        } else if (get(response, 'payload.message')) {
          const errorMessage = get(response, 'payload.message', 'Something Went Wrong');
          dispatch(
            setSnackbar({
              snackbarOpen: true,
              snackbarType: 'error',
              snackbarMessage: errorMessage,
            })
          );
        }
      })
      .catch((error) => {
        console.log('error==> ', error);
      });
  };
  const addElementTabs = [
    { label: 'Create Element', value: 'createElement' },
    { label: 'Add Existing Element', value: 'addElement' },
  ];
  const filteredRasHeadCells = useMemo(() => {
    const dsPageColumnSettings = JSON.parse(localStorage.getItem('dsPageColumnSettings'));
    if (!dsPageColumnSettings) {
      localStorage.setItem('dsPageColumnSettings', JSON.stringify(defaultDsPageColumnSettings));
      return rasHeadCells;
    }
    if (filteredColSettings && filteredColSettings?.rasStudy) {
      return rasHeadCells.filter((cell) => filteredColSettings?.rasStudy?.[cell?.id]);
    }
    return rasHeadCells;
  }, [filteredColSettings, rasHeadCells]);
  const filteredDotsHeadCells = useMemo(() => {
    const dsPageColumnSettings = JSON.parse(localStorage.getItem('dsPageColumnSettings'));
    if (!dsPageColumnSettings) {
      localStorage.setItem('dsPageColumnSettings', JSON.stringify(defaultDsPageColumnSettings));
      return headCells.filter((cell) => cell?.id !== 'roleName');
    }
    if (filteredColSettings && filteredColSettings?.dotsStudy) {
      return headCells.filter(
        (cell) => (cell?._id === 'isEc' || filteredColSettings?.dotsStudy?.[cell?.id]) && cell?.id !== 'roleName'
      );
    }
    return headCells.filter((cell) => cell?.id !== 'roleName');
  }, [filteredColSettings, headCells]);
  const filteredDilosHeadCells = useMemo(() => {
    const dsPageColumnSettings = JSON.parse(localStorage.getItem('dsPageColumnSettings'));
    if (!dsPageColumnSettings) {
      localStorage.setItem('dsPageColumnSettings', JSON.stringify(defaultDsPageColumnSettings));
      return headCells;
    }
    if (filteredColSettings && filteredColSettings?.diloStudy) {
      return headCells.filter((cell) => cell?._id === 'isEc' || filteredColSettings?.diloStudy?.[cell?.id]);
    }
    return headCells;
  }, [filteredColSettings, headCells]);
  const finalHeadCells = useMemo(() => {
    if (currentStudyType === 1) {
      return filteredRasHeadCells;
    }
    if (currentStudyType === 2) {
      return filteredDilosHeadCells;
    }
    if (currentStudyType === 3) {
      return filteredDotsHeadCells;
    }
    return [];
  }, [currentStudyType, filteredDilosHeadCells, filteredDotsHeadCells, filteredRasHeadCells]);
  const nestedRowProps = {
    rolesSelectOptions,
    areasOptions,
    elementSelectOptions,
    taskSelectOptions,
    fetchDataSummaries,
    setCurrentTask,
    openModal,
  };
  const { studies } = useSelector((state) => state.studies);
  const isRemoveValid = (selected, visibleRows, studyType) => {
    // if length 0, if study ras
    if (selected?.length <= 1) {
      return true;
    }
    const firstElement = visibleRows.find((row) => row?._id === selected[0]);
    return selected.every((selectedID, index) => {
      const element = visibleRows.find((row) => row?._id === selectedID);
      if (studyType === 1) {
        return (
          element?.studyID === firstElement?.studyID &&
          element?.studyType === firstElement?.studyType &&
          element?.roundID === firstElement?.roundID
        );
      }
      return element?.studyID === firstElement?.studyID && element?.studyType === firstElement?.studyType;
    });
  };
  const getSingleStudiesPayload = (selectedRows, studyType) => {
    if (selectedRows && selectedRows?.length > 0) {
      const studyRow = summaryRows.find((row) => row?._id === selectedRows[0]);

      const payload = {
        studyID: studyRow?.studyID,
        studyType: currentStudyType,
        observationIDs: selectedRows,
      };
      if (currentStudyType === 1) {
        payload.roundID = studyRow?.roundID;
      }
      return payload;
    }
    return null; // Return empty array if no rows are selected
  };
  const handleEcElement = (selected, visibleRows, handleClearSelection) => {
    if (selected?.length > 1) {
      const firstElement = visibleRows.find((row) => row?._id === selected[0]);
      const payload = {
        studyID: firstElement?.studyID,
        studyType: firstElement?.studyType,
        observationIDs: selected,
      };
      dispatch(addEc(payload))
        .then((response) => {
          if (response.payload?.success) {
            dispatch(
              setSnackbar({
                snackbarOpen: true,
                snackbarType: 'success',
                snackbarMessage: get(response, 'payload.message', 'EC Added Successfully'),
              })
            );
            handleClearSelection();
            fetchDataSummaries();
          } else if (get(response, 'payload.message')) {
            const errorMessage = get(response, 'payload.message', 'Something Went Wrong');
            dispatch(
              setSnackbar({
                snackbarOpen: true,
                snackbarType: 'error',
                snackbarMessage: errorMessage,
              })
            );
          }
        })
        .catch((error) => {
          console.log('error==> ', error);
        });
    }
  };
  const isEcValid = (selected, visibleRows) => {
    // if length 0, if study ras
    if (selected?.length <= 1) {
      return false;
    }
    const firstElement = visibleRows.find((row) => row?._id === selected[0]);
    return selected.every((selectedID, index) => {
      const element = visibleRows.find((row) => row?._id === selectedID);
      if (element && element?.studyType === 1) {
        return false;
      }
      return (
        element?.studyID === firstElement?.studyID &&
        element?.studyType === firstElement?.studyType &&
        element?.elementID === firstElement?.elementID &&
        element?.areaID === firstElement?.areaID &&
        element?.taskID === firstElement?.taskID
      );
    });
  };
  const toolbarProps = {
    setFilterConfig,
    isRemoveValid,
    getSingleStudiesPayload,
    isEcValid: currentStudyType === 1 ? null : isEcValid,
    type: 'dataSummary',
    handleEcElement,
    studyType: currentStudyType,
    fetchStudy: fetchDataSummaries,
    taskSelectOptions,
    rolesSelectOptions,
    areasOptions,
    ratingOptions,
    elementSelectOptions,
  };
  const onDelete = async (row, startLoading, closeLoading) => {
    if (row?._id && projectID) {
      startLoading();
      try {
        const response = await axiosInstance({
          url: 'studies/delete/obs',
          method: 'DELETE',
          data: {
            studyID: row?.studyID,
            roundID: row?.roundID,
            observationIDs: [row?._id],
            studyType: row?.studyType,
          },
        });
        if (response?.data && response?.data?.success) {
          dispatch(
            setSnackbar({
              snackbarOpen: true,
              snackbarType: 'success',
              snackbarMessage: get(response, 'data.message'),
            })
          );
          dispatch(getRoles({ projectID }));
          dispatch(removeSummary({ observationIDs: [row?._id] }));
          // fetchDataSummaries();
        }
      } catch (error) {
        const errorMessage = get(error, 'response.data.message', 'Something Went Wrong');
        dispatch(
          setSnackbar({
            snackbarOpen: true,
            snackbarType: 'error',
            snackbarMessage: errorMessage,
          })
        );
      } finally {
        closeLoading();
      }
    } else {
      console.warn('No Row Id found');
    }
  };
  if (!currentStudyType) {
    return (
      <Stack minHeight={300} gap={2} direction="column" width="100%" justifyContent="center" alignItems="center">
        <img src={EmptyIcon} alt="empty" />

        <Typography variant="h5">Select a study type to get data summary</Typography>
        {isStudyStatusLoading ? (
          <Stack gap={2} direction="row">
            <Skeleton width={120} height={40} />
            <Skeleton width={120} height={40} />
            <Skeleton width={120} height={40} />
          </Stack>
        ) : (
          <Stack gap={2} direction="row">
            {singleProjectData?.studyTypes?.includes(1) && validStudies?.includes(1) && (
              <ThemeButton
                sx={{
                  color: getStudyColor(1),
                  '&:hover': {
                    bgcolor: () => alpha(getStudyColor(1), 0.2),
                    borderColor: getStudyColor(1),
                  },
                  borderColor: getStudyColor(1),
                }}
                onClick={() => setCurrentStudyType(1)}
                variant="outlined"
              >
                Efficiency Study
              </ThemeButton>
            )}
            {singleProjectData?.studyTypes?.includes(2) && validStudies?.includes(2) && (
              <ThemeButton
                sx={{
                  color: getStudyColor(2),
                  borderColor: getStudyColor(2),
                  '&:hover': {
                    bgcolor: () => alpha(getStudyColor(2), 0.2),
                    borderColor: getStudyColor(2),
                  },
                }}
                onClick={() => setCurrentStudyType(2)}
                variant="outlined"
              >
                Role Study
              </ThemeButton>
            )}
            {singleProjectData?.studyTypes?.includes(3) && validStudies?.includes(3) && (
              <ThemeButton
                sx={{
                  color: getStudyColor(3),
                  borderColor: getStudyColor(3),
                  '&:hover': {
                    bgcolor: () => alpha(getStudyColor(3), 0.2),
                    borderColor: getStudyColor(3),
                  },
                }}
                onClick={() => setCurrentStudyType(3)}
                variant="outlined"
              >
                Activity Study
              </ThemeButton>
            )}
          </Stack>
        )}
      </Stack>
    );
  }
  const handleColumnChange = (event, cell) => {
    if (currentStudyType === 1) {
      const newColSettings = {
        ...filteredColSettings,
        rasStudy: { ...filteredColSettings?.rasStudy, [cell?.id]: event.target.checked },
      };
      setFilteredColSettings(newColSettings);
      localStorage.setItem('dsPageColumnSettings', JSON.stringify(newColSettings));
    } else if (currentStudyType === 2) {
      const newColSettings = {
        ...filteredColSettings,
        diloStudy: { ...filteredColSettings?.diloStudy, [cell?.id]: event.target.checked },
      };
      setFilteredColSettings(newColSettings);
      localStorage.setItem('dsPageColumnSettings', JSON.stringify(newColSettings));
    } else if (currentStudyType === 3) {
      const newColSettings = {
        ...filteredColSettings,
        dotsStudy: { ...filteredColSettings?.dotsStudy, [cell?.id]: event.target.checked },
      };
      setFilteredColSettings(newColSettings);
      localStorage.setItem('dsPageColumnSettings', JSON.stringify(newColSettings));
    }
  };
  const isCellVisible = (cell) => {
    if (currentStudyType === 1) {
      return filteredColSettings?.rasStudy ? filteredColSettings?.rasStudy[cell?.id] : false;
    }
    if (currentStudyType === 2) {
      return filteredColSettings?.diloStudy ? filteredColSettings?.diloStudy[cell?.id] : false;
    }
    return filteredColSettings?.dotsStudy ? filteredColSettings?.dotsStudy[cell?.id] : false;
  };
  const closeElementModal = () => {
    setIsObsModalOpen((prevState) => false);
    // form2.reset();
  };
  const closeObsModal = () => {
    setIsObsModalOpen((prevState) => false);
    setElementCount(true);
    reset();
  };

  const addObservation = (cell) => {
    setValue('studyID', get(cell, 'studyID', ''));
    setValue('areaID', '');
    setValue('roundID', get(cell, 'roundID', ''));
    setValue('addAtObservationID', get(cell, 'observationID', ''));
    setValue('elementID', '');
    setValue('roleID', '');
    setValue('rating', '');
    setValue('frequency', '');
    setValue('notes', '');
    setIsObsModalOpen(true);
    const currentRound = singleStudyData?.rounds && singleStudyData?.rounds?.find((round) => round?.roundID === cell?.roundID);
    if (currentRound && currentRound?.data?.length > 0) {
      const filteredRolesOptions = defaultRolesOptions.filter(
        (roleOption) => !currentRound?.data?.some((round) => round?.roleID === roleOption?.value)
      );
      if (filteredRolesOptions && !!filteredRolesOptions?.length) {
        setRolesOptions(filteredRolesOptions);
      } else {
        setRolesOptions([]);
      }
    }
  };
  const frequencyInputFields = register('frequency');
  const frequencyValue = watch('frequency');
  const onElementSubmit = (values) => {
    if (selectedElementTab === 'createElement') {
      const { tasks: taskValues, ...rest } = values;
      const payload = { ...rest };
      if ((values.studyTypes.includes(2) || values.studyTypes.includes(3)) && taskValues && taskValues.length > 0) {
        const newTaskValues = taskValues.map((task) => ({
          ...task,
          elements: task.elements?.length > 0 ? task.elements.map((el) => (el === tempID ? values?.name : el)) : task.elements,
        }));
        payload.tasks = newTaskValues;
      }
      if (get(values, 'rating')) {
        payload.rating = parseInt(values.rating, 10);
      }
      if (get(values, 'type')) {
        payload.type = parseInt(values.type, 10);
      }

      if (customerID && projectID) {
        dispatch(addElement({ ...payload, defaultEntry: false, customerID, projectID }))
          .then((response) => {
            if (response.payload?.success) {
              dispatch(
                setSnackbar({
                  snackbarOpen: true,
                  snackbarType: 'success',
                  snackbarMessage: get(response, 'payload.message', 'Element Added Successfully'),
                })
              );
              dispatch(getElements({ projectID, defaultEntry: false }));
              dispatch(getTasks({ projectID }));
              closeModal();
            } else if (get(response, 'payload.message')) {
              const errorMessage = get(response, 'payload.message', 'Something Went Wrong');
              dispatch(
                setSnackbar({
                  snackbarOpen: true,
                  snackbarType: 'error',
                  snackbarMessage: errorMessage,
                })
              );
            }
          })
          .catch((error) => {
            console.log('error==> ', error);
          });
      }
    } else {
      // const demo = {
      //   name: 'Belted Till Transaction',
      //   controllingElementID: '654e1584a44292fc20f5a32c',
      //   elements: [],
      //   studyTypes: [3, 2],
      //   customerID: '5d108dd9f6bbda74fd2c043b',
      //   projectID: '661e5350925699c6011494fc',
      //   _id: '654e0b37c5a7470f6159ea12',
      // };
      const { currentElement, ...rest } = values;
      const selectedTask = currentTask && tasks && tasks?.length > 0 ? tasks.find((task) => task?._id === currentTask) : null;
      if (selectedTask && currentElement) {
        const taskPayload = {
          name: get(selectedTask, 'name'),
          controllingElementID: get(selectedTask, 'projectSetting.controllingElementID'),
          studyTypes: get(selectedTask, 'projectSetting.studyTypes'),
          customerID: get(selectedTask, 'projectSetting.customerID'),
          projectID: get(selectedTask, 'projectSetting.projectID'),
          _id: get(selectedTask, '_id'),
          elements: get(selectedTask, 'projectSetting.elements')
            ? [...get(selectedTask, 'projectSetting.elements'), currentElement]
            : [currentElement],
        };
        dispatch(updateTask(taskPayload))
          .then((response) => {
            if (response.payload?.success) {
              dispatch(
                setSnackbar({
                  snackbarOpen: true,
                  snackbarType: 'success',
                  snackbarMessage: get(response, 'payload.message', 'Task Updated Successfully'),
                })
              );
              dispatch(getTasks({ projectID }));
              dispatch(getElements({ projectID, defaultEntry: false }));
              closeModal();
            } else if (get(response, 'payload.message')) {
              const errorMessage = get(response, 'payload.message', 'Something Went Wrong');
              dispatch(
                setSnackbar({
                  snackbarOpen: true,
                  snackbarType: 'error',
                  snackbarMessage: errorMessage,
                })
              );
            }
          })
          .catch((error) => {
            console.log('error==> ', error);
          });
      }
    }
  };
  return (
    <div>
      <ModalComponent
        persist
        PaperProps={{
          sx: {
            width: '100%',
            maxWidth: '1400px !important',
            minHeight: '92.9vh',
          },
        }}
        title="Create Element"
        open={isModalopen}
        onClose={closeModal}
        DialogActions={
          <DialogActions sx={{ mt: 2, px: 0, display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
            <ThemeButton type="button" onClick={closeModal} color="error" variant="outlined">
              Cancel
            </ThemeButton>
            <ThemeButton
              onClick={form2?.handleSubmit(onElementSubmit)}
              type="submit"
              loading={addElementLoading}
              variant="contained"
            >
              Save Changes
            </ThemeButton>
          </DialogActions>
        }
      >
        <form onSubmit={form2?.handleSubmit(onElementSubmit)}>
          {currentStudyType !== 1 && (
            <ReusableTabs tabs={addElementTabs} selectedTab={selectedElementTab} handleChange={onElementTabChange} />
          )}
          {selectedElementTab === 'createElement' ? (
            <Grid mt rowSpacing={1} columnSpacing={3} container>
              <Grid className="left-side" item xs={12} md={6} lg={6}>
                <Grid rowSpacing={1} columnSpacing={1} container>
                  <Grid sx={{ '& .MuiFormControl-root': { marginBottom: 0 } }} className="name" item xs={12} md={12} lg={12}>
                    <FormInput
                      width="90%"
                      label="Name"
                      placeholder="e.g Greet Customer"
                      InputProps={{
                        ...form2?.register('name'),
                        onBlur: (event) => setCurrentElementName(event.target.value),
                      }}
                      error={!!form2?.formState?.errors?.name}
                      helperText={form2?.formState?.errors?.name && form2?.formState?.errors?.name?.message}
                    />
                  </Grid>
                  <Grid className="unit-of-measures" item xs={12} md={6}>
                    <FormInput
                      label="Unit of Measure"
                      placeholder="e.g Per item"
                      // fontSize="0.9375rem"
                      // fontWeight={600}
                      InputProps={{
                        ...form2?.register('unitOfMeasure'),
                      }}
                      width="90%"
                      error={!!form2?.formState?.errors?.unitOfMeasure}
                      helperText={form2?.formState?.errors?.unitOfMeasure && form2?.formState?.errors?.unitOfMeasure?.message}
                    />
                  </Grid>
                  <Grid className="category" item xs={12} md={6}>
                    <label id="category-select" style={{ fontSize: '0.8600rem' }}>
                      Category
                    </label>
                    <CustomSelect
                      width="90%"
                      name="categoryID"
                      // InputProps={{ ...register('customerID') }}
                      control={form2?.control}
                      options={categoryFieldOptions}
                      label="Select a category"
                      // value={watch('customerID')}
                      error={!!form2?.formState?.errors?.categoryID}
                      helperText={form2?.formState?.errors?.categoryID && form2?.formState?.errors?.categoryID?.message}
                      // placeholder=Chooseexpiration date"
                    />
                  </Grid>
                  <Grid className="type" item xs={12} md={4}>
                    <Box
                      sx={{
                        // '& .css-dmmspl-MuiFormGroup-root': { mt: 0.5 },
                        '& .css-14r9679-MuiFormLabel-root.Mui-focused': { color: '#212B36' },
                        '& .css-14r9679-MuiFormLabel-root': { color: '#212B36', fontWeight: '600' },
                        '& .MuiFormLabel-root .Mui-error ': { color: '#212B36', fontWeight: '600' },
                        mt: 1,
                      }}
                    >
                      <GroupCheckbox
                        error={!!form2?.formState?.errors?.type}
                        helperText={form2?.formState?.errors?.type && form2?.formState?.errors?.type?.message}
                        label="Type"
                      >
                        <FormRadioGroup row fontSize={18} options={typeOptions} control={form2.control} name="type" />
                      </GroupCheckbox>
                    </Box>
                  </Grid>
                  <Grid mt={1} item xs={12} md={4}>
                    <FormInput
                      endAdornment={<InputAdornment position="end">%</InputAdornment>}
                      type="number"
                      label="Relaxation Allowance"
                      placeholder="e.g 1"
                      // fontSize="0.9375rem"
                      // fontWeight={600}
                      InputProps={{
                        ...form2?.register('relaxationAllowance'),
                      }}
                      width="18ch"
                      error={!!form2?.formState?.errors?.relaxationAllowance}
                      helperText={
                        form2?.formState?.errors?.relaxationAllowance && form2?.formState?.errors?.relaxationAllowance?.message
                      }
                    />
                  </Grid>
                  <Grid mt={1} item xs={12} md={4}>
                    <FormInput
                      type="number"
                      label="Contingency Allowance"
                      placeholder="e.g 1"
                      endAdornment={<InputAdornment position="end">%</InputAdornment>}
                      // fontSize="0.9375rem"
                      // fontWeight={600}
                      InputProps={{
                        ...form2?.register('contingencyAllowance'),
                      }}
                      width="18ch"
                      error={!!form2?.formState?.errors?.contingencyAllowance}
                      helperText={
                        form2?.formState?.errors?.contingencyAllowance && form2?.formState?.errors?.contingencyAllowance?.message
                      }
                    />
                  </Grid>

                  <Grid className="count" item xs={12} md={12}>
                    <ThemeSwitch
                      description="Relevant only on Role and Activity studies"
                      label="Count"
                      control={form2?.control}
                      name="count"
                      width="90%"
                    />
                  </Grid>
                  <Grid className="study-types" item xs={12} md={12}>
                    <CheckboxGroup
                      row
                      control={form2?.control}
                      label="Study Types"
                      name="studyTypes"
                      options={studyTypeOptions}
                      config={studyTypeConfig}
                    />
                  </Grid>
                  <Grid className="rating" item xs={12} md={12}>
                    <Box
                      sx={{
                        '& .css-dmmspl-MuiFormGroup-root': { mt: 0.5 },
                        '& .css-14r9679-MuiFormLabel-root.Mui-focused': { color: '#212B36' },
                        '& .css-14r9679-MuiFormLabel-root': { color: '#212B36', fontWeight: '600' },
                        '& .MuiFormLabel-root .Mui-error ': { color: '#212B36', fontWeight: '600' },
                        width: '80%',
                      }}
                    >
                      <GroupCheckbox
                        error={!!form2?.formState?.errors?.rating}
                        helperText={form2?.formState?.errors?.rating && form2?.formState?.errors?.rating?.message}
                        label="Default Rating"
                      >
                        <FormRadioGroup fontSize={18} options={ratingsOptions} control={form2?.control} name="rating" />
                      </GroupCheckbox>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
              <Grid className="right-side" item xs={12} md={6} lg={6}>
                <Grid rowSpacing={1} columnSpacing={1} container>
                  {studyTypesValues && (studyTypesValues.includes(2) || studyTypesValues.includes(3)) && (
                    <Grid item xs={12} lg={12} md={12}>
                      {/* <FormHelperText style={{ fontSize: '0.8600rem', marginBottom: 8, fontWeight: 'unset' }}>Tasks</FormHelperText> */}
                      <Stack mb={2} justifyContent="space-between" direction="row">
                        <Stack width="100%" direction="column">
                          <Typography variant="subtitle2">Tasks</Typography>
                          {get(errors, 'elements.root.message') && (
                            <Typography sx={{ color: 'error.main' }} variant="caption">
                              {get(errors, 'elements.root.message')}
                            </Typography>
                          )}
                        </Stack>
                      </Stack>
                      {fields2?.length > 0 ? (
                        <Stack>
                          {fields2.map((field, index) => (
                            <Stack key={field?.id} width="100%" direction="column" gap mb={1}>
                              <Stack gap direction="row" width="100%">
                                <Box width="100%">
                                  <Card
                                    onClick={() => rowClicked(field?.taskID)}
                                    sx={{
                                      cursor: 'pointer',
                                      p: 2,
                                      bgcolor: '#f5f5f5f0',
                                      '&:hover': {
                                        backgroundColor: '#ebe9e9f0',
                                      },
                                    }}
                                  >
                                    <Stack width="100%" alignItems="center" justifyContent="space-between" direction="row">
                                      <Typography fontWeight="bold" variant="caption">
                                        {field?.taskName}
                                      </Typography>
                                      {collapsedRow === field?.taskID ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                    </Stack>
                                  </Card>
                                </Box>
                                <IconButton onClick={() => remove(index)} sx={{ mt: 0 }}>
                                  <CancelIcon color="error" />
                                </IconButton>
                              </Stack>
                              <Collapse in={collapsedRow === field?.taskID}>
                                <DndContext
                                  modifiers={[restrictToFirstScrollableAncestor, restrictToVerticalAxis]}
                                  // autoScrol={false}
                                  sensors={sensors}
                                  collisionDetection={closestCenter}
                                  onDragEnd={(event) => handleDragEnd(event, field, index)}
                                >
                                  {get(tasksValues, `${index}.elements`) && (
                                    <SortableContext
                                      items={get(tasksValues, `${index}.elements`, [])}
                                      strategy={verticalListSortingStrategy}
                                    >
                                      <Box
                                        py={2}
                                        sx={{
                                          bgcolor: '#f5f5f5f0',
                                          overflowY: 'auto',
                                          maxHeight: 300,
                                          width: 'calc(100% - 50px)',
                                        }}
                                      >
                                        {get(tasksValues, `${index}.elements`)?.length > 0 &&
                                          tasksValues[index]?.elements?.map((element) => {
                                            const elementFound = elements.find((value) => value?._id === element);
                                            if (elementFound || element === tempID) {
                                              return (
                                                <SortableElement sx={{ cursor: 'grab !important' }} id={element} key={element}>
                                                  <Box
                                                    gap={2}
                                                    p={2}
                                                    my={1}
                                                    width={500}
                                                    height={20}
                                                    alignItems="center"
                                                    display="flex"
                                                  >
                                                    <DragIndicatorIcon sx={{ color: '#636c72ed' }} fontSize="small" />
                                                    <Typography
                                                      sx={{ color: element === tempID ? 'primary.main' : 'text.main' }}
                                                      variant="subtitle2"
                                                    >
                                                      {element === tempID
                                                        ? currentElementname || 'Current Element'
                                                        : elements.find((value) => value?._id === element)?.name}
                                                    </Typography>
                                                  </Box>
                                                </SortableElement>
                                              );
                                            }
                                            return null;
                                          })}
                                      </Box>
                                    </SortableContext>
                                  )}
                                </DndContext>
                              </Collapse>
                            </Stack>
                          ))}
                        </Stack>
                      ) : null}
                      <Stack direction="row" width="100%" justifyContent="flex-end">
                        <ThemeButton onClick={addTaskClick}>Add Task</ThemeButton>
                        <Menu
                          id="long-menu"
                          MenuListProps={{
                            'aria-labelledby': 'long-button',
                          }}
                          anchorEl={anchorEl}
                          open={open}
                          onClose={handleClose}
                          // PaperProps={{
                          //   style: {
                          //     maxHeight: ITEM_HEIGHT * 4.5,
                          //     width: '20ch',
                          //   },
                          // }}
                        >
                          {tasksOptions && tasksOptions?.length > 0 ? (
                            tasksOptions.map((option) => (
                              <MenuItem
                                value={option?.value}
                                key={option?.value}
                                onClick={(event) => handleSelectTask(event, option?.value)}
                                disabled={tasksValues.some((task) => task?.taskID === option?.value)}
                              >
                                {option?.label}
                              </MenuItem>
                            ))
                          ) : (
                            <MenuItem>
                              <i>No task available</i>
                            </MenuItem>
                          )}
                        </Menu>
                      </Stack>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          ) : (
            <Stack sx={{ width: { md: '50%', sm: '100%' } }}>
              <label htmlFor="elementID" style={{ fontSize: '0.8600rem' }}>
                Add Element
              </label>
              {/* <FormInput
                      endAdornment={<InputAdornment position="end">%</InputAdornment>}
                      type="number"
                      label="Relaxation Allowance"
                      placeholder="e.g 1"
                      // fontSize="0.9375rem"
                      // fontWeight={600}
                      InputProps={{
                        ...form2?.register('relaxationAllowance'),
                      }}
                      width="18ch"
                      error={!!form2?.formState?.errors?.relaxationAllowance}
                      helperText={
                        form2?.formState?.errors?.relaxationAllowance && form2?.formState?.errors?.relaxationAllowance?.message
                      }
                    /> */}
              <CustomSelect
                id="elementID"
                width="95%"
                control={form2?.control}
                name="currentElement"
                options={elementSelectOptions || []}
                label="Select an element"
                error={!!form2?.formState?.errors?.currentElement}
                helperText={form2?.formState?.errors?.currentElement && form2?.formState?.errors?.currentElement?.message}
              />
            </Stack>
          )}
        </form>
      </ModalComponent>
      <ModalComponent
        persist
        PaperProps={{
          sx: {
            width: '100%',
            maxWidth: '1200px !important',
          },
        }}
        title="Create Observation"
        open={isObsModalOpen}
        onClose={closeObsModal}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid rowSpacing={1} columnSpacing={3} container>
            <Grid sx={{ '& .MuiFormControl-root': { marginBottom: 0 } }} className="name" item xs={12} md={12} lg={6}>
              <label style={{ fontSize: '0.8600rem' }}>Role</label>
              <CustomSelect
                name="roleID"
                label="Select Role"
                control={control}
                options={rolesOptions}
                error={!!errors?.roleID}
                helperText={errors?.roleID && errors?.roleID?.message}
              />
            </Grid>
            <Grid sx={{ '& .MuiFormControl-root': { marginBottom: 0 } }} className="name" item xs={12} md={12} lg={6}>
              <label style={{ fontSize: '0.8600rem' }}>Area</label>
              <CustomSelect
                name="areaID"
                label="Select Area"
                control={control}
                options={areasOptions}
                error={!!errors?.areaID}
                helperText={errors?.areaID && errors?.areaID?.message}
              />
            </Grid>
            <Grid sx={{ '& .MuiFormControl-root': { marginBottom: 0 } }} className="name" item xs={12} md={12} lg={6}>
              <label style={{ fontSize: '0.8600rem' }}>Element</label>
              <CustomSelect
                name="elementID"
                label="Select Element"
                control={control}
                options={elementSelectOptions}
                error={!!errors?.elementID}
                helperText={errors?.elementID && errors?.elementID?.message}
              />
            </Grid>
            <Grid sx={{ '& .MuiFormControl-root': { marginBottom: 0 } }} className="name" item xs={12} md={12} lg={6}>
              <label style={{ fontSize: '0.8600rem' }}>Rating</label>
              <CustomSelect
                name="rating"
                label="Select Rating"
                control={control}
                options={obsRatingOptions}
                error={!!errors?.rating}
                helperText={errors?.rating && errors?.rating?.message}
                isDisabled={() => elementRating === 1 || elementRating === 2}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
              <FormInput
                label="Frequency"
                type="number"
                placeholder="Write frequency"
                disabled={!elementCount}
                InputProps={{
                  name: frequencyInputFields.name,
                  onblur: frequencyInputFields.onBlur,
                  ref: frequencyInputFields.ref,
                  value: frequencyValue,
                  onChange: (event) => {
                    const newValue = event.target.value;
                    if (newValue === '' || Number(newValue) >= 0) {
                      return frequencyInputFields.onChange(event);
                    }
                    return null;
                  },
                }}
                error={!!errors?.frequency}
                helperText={errors?.frequency && errors?.frequency?.message}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
              <FormInput
                label="Notes"
                placeholder="Write your notes"
                InputProps={{ ...register('notes') }}
                error={!!errors?.notes}
                helperText={errors?.notes && errors?.notes?.message}
              />
            </Grid>
          </Grid>

          <DialogActions sx={{ mt: 2, px: 0, display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
            <ThemeButton type="button" onClick={closeElementModal} color="error" variant="outlined">
              Cancel
            </ThemeButton>
            <ThemeButton loading={addObservationLoading} type="submit" variant="contained">
              Create Observation
            </ThemeButton>
          </DialogActions>
        </form>
      </ModalComponent>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Stack mb={2}>
          {currentStudyType && <Typography variant="h6">Data summary for {getStudyName(currentStudyType)} Study</Typography>}
          {obsLoading ? (
            <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
          ) : (
            dataSummaries && <Typography variant="subtitle2">{dataSummaries?.length} Observations</Typography>
          )}
        </Stack>
        <Stack gap={2} direction="row">
          {singleProjectData?.studyTypes?.includes(1) && validStudies?.includes(1) && (
            <ThemeButton
              sx={{
                color: getStudyColor(1),
                '&:hover': {
                  bgcolor: () => alpha(getStudyColor(1), 0.2),
                  borderColor: getStudyColor(1),
                },
                borderColor: getStudyColor(1),
              }}
              onClick={() => setCurrentStudyType(1)}
              variant="outlined"
            >
              Efficiency Study
            </ThemeButton>
          )}
          {singleProjectData?.studyTypes?.includes(2) && validStudies?.includes(2) && (
            <ThemeButton
              sx={{
                color: getStudyColor(2),
                '&:hover': {
                  bgcolor: () => alpha(getStudyColor(2), 0.2),
                  borderColor: getStudyColor(2),
                },
                borderColor: getStudyColor(2),
              }}
              onClick={() => setCurrentStudyType(2)}
              variant="outlined"
            >
              Role Study
            </ThemeButton>
          )}
          {singleProjectData?.studyTypes?.includes(3) && validStudies?.includes(3) && (
            <ThemeButton
              sx={{
                color: getStudyColor(3),
                '&:hover': {
                  bgcolor: () => alpha(getStudyColor(3), 0.2),
                  borderColor: getStudyColor(3),
                },
                borderColor: getStudyColor(3),
              }}
              onClick={() => setCurrentStudyType(3)}
              variant="outlined"
            >
              Activity Study
            </ThemeButton>
          )}
        </Stack>
      </Stack>
      {summaryRows && (
        <>
          <ModalComponent
            persist
            PaperProps={{
              sx: {
                width: '100%',
                maxWidth: '600px !important',
                maxHeight: '700px !important',
              },
            }}
            title="Filter"
            open={isFilterOpen}
            onClose={setIsFilterOpen}
          >
            <FilterModal
              setFilterConfig={setFilterConfig}
              filterConfig={filterConfig}
              setFilterBy={setFilterBy}
              filterOptions={filterOptions}
              closeFilter={closeFilter}
              filterBy={filterBy}
              sortBy={sortBy}
            />
          </ModalComponent>
          <ModalComponent
            persist
            PaperProps={{
              sx: {
                width: '100%',
                maxWidth: '500px !important',
                maxHeight: '700px !important',
              },
            }}
            title="Hide/Show Columns"
            open={isColumnsModalOpened}
            onClose={setColumnsModal}
            closeButton
          >
            <Box>
              {currentStudyType &&
                (currentStudyType === 1 ? rasHeadCells : headCells)?.map(
                  (cell) =>
                    cell?.id !== 'isEc' && (
                      <ThemeSwitch
                        label={cell?.label}
                        onChange={(event) => handleColumnChange(event, cell)}
                        checked={isCellVisible(cell)}
                      />
                    )
                )}
            </Box>
          </ModalComponent>
          <DataTable
            filterKeys={['taskName', 'elementName', 'startTime', 'studyName']}
            customToolbar={Types.toolbarTypes.SINGLE_STUDY}
            headCells={finalHeadCells}
            rows={!delayedLoading ? summaryRows : []}
            filterColumns={filterConfig?.filterColumns}
            filterBy={filterConfig?.filterBy}
            sortOrder={filterConfig?.sortOrder}
            sortBy={filterConfig?.sortBy}
            filterConfig={filterConfig}
            setElementFilterConfig={setFilterConfig}
            // customColumnType={study?.studyType}
            enableActions
            // addObservation={addObservation}
            nestedRowKey={Types.nestedRowTypes.STUDY}
            customColumnType="studyType"
            disableNestedRowClick
            enableCheckbox
            hideNestedArrow
            nestedRowProps={nestedRowProps}
            onEditClick={onEditClick}
            handleRowClick={onEditClick}
            isLoading={delayedLoading}
            toolbarProps={toolbarProps}
            onRowDelete={onDelete}
            enableDelete
            maxHeight={453}
            defaultRowsPerPage={-1}
            hideCompactViewButton
            onSettingsClick={() => setColumnsModal(true)}
          />
        </>
      )}
    </div>
  );
};

export default DataSummaryPage;

DataSummaryPage.propTypes = {
  projectID: PropTypes.string,
  customerID: PropTypes.string,
  singleProjectData: PropTypes.object,
};
